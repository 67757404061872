// /var/www/website2024/192.testproject.work/maracar8/0.8.2/myapp/src/api/api.js

let API_ENV = 'api_env_3'; // default environment

export const setAPIEnv = (env) => {
  API_ENV = env;
};

const API_BASE_URL = 'https://py-maracar.afaa.website';
const API_AA_GO_URL = 'https://go.afaa.website';
const getUserID = () => localStorage.getItem('user_ID');

export const API_URLS = {


  
  TRANSLATIONS: 'https://afaa.website/json/crm-01/InvoiceDataTranslations.json',
  FIXED_RATE: 'https://afaa.website/json/crm-01/FixedRateConnAPI.json',
  STORE_CONSTANTS: 'https://afaa.website/json/crm-01/StoreConstant.json',
  CURRENCIES: 'https://afaa.website/json/crm-01/crm-currencies.json',  

  BOOKING_DETAILS: (bookingID) => bookingID
    ? `${API_BASE_URL}/booking/${API_ENV}/view?sort_by=BookingID&sort=desc&BookingID=${bookingID}&branch_user=${getUserID()}`
    : `${API_BASE_URL}/booking/${API_ENV}/view?sort_by=BookingID&sort=desc&branch_user=${getUserID()}`,

  CUSTOMER_DETAILS: (customerID) => `${API_BASE_URL}/register/${API_ENV}/user?user_ID=${customerID}`,
  BOOKING_DETAILS_EXTENDED: (bookingID) => `${API_BASE_URL}/booking/${API_ENV}/detail_view?BookingID=${bookingID}&branch_user=${getUserID()}`,
  SERVICE_PRICE: (serviceID) => `${API_BASE_URL}/price/${API_ENV}/price_service?ServiceID=${serviceID}&branch_user=${getUserID()}`,
  BRANCH_DETAILS: (userID) => `${API_BASE_URL}/branches/${API_ENV}/view?user_ID=${userID}&order_by=branch_ID&order_direction=ASC&limit=1`,

  ASSIGN_QUEUE: () => `${API_BASE_URL}/queues/${API_ENV}/assign?branch_user=${getUserID()}`,
  QUEUES_VIEW: `${API_BASE_URL}/queues/${API_ENV}/view?Status=1&sort_by=ExpectedStartTime&sort=asc&branch_user=${getUserID()}`,

  ALL_USERS: (userID, firstName = '', positionID = '') =>
    `${API_BASE_URL}/register/${API_ENV}/all_users?user_ID=${userID}&first_name=${firstName}&position_ID=${positionID}&branch_user=${getUserID()}`,

  BOOKING_NOTIFICATIONS: `${API_BASE_URL}/booking/${API_ENV}/detail_view_price?page=1&limit=300&sort=desc&branch_user=${getUserID()}`,
  BOOKING_DETAIL_PRICE: (bookingID) => `${API_BASE_URL}/booking/${API_ENV}/detail_view_price?BookingID=${bookingID}&sort=asc&sort_by=ServiceDate&branch_user=${getUserID()}`,

  UPDATE_EMPLOYEE: (bookingDetailID) => `${API_BASE_URL}/register/api/update_status?table_name=BookingsEmployeeID&id_field_name=BookingDetailID&record_id=${bookingDetailID}`,

  UPDATE_BOOKING_STATUS: (bookingID) => `${API_BASE_URL}/register/api/update_status?user_ID=${getUserID()}&table_name=Bookings&id_field_name=BookingID&record_id=${bookingID}`,

  UPDATE_PAYMENT_STATUS: (bookingID) => `${API_BASE_URL}/register/api/update_status?user_ID=${getUserID()}&table_name=BookingsPaymentStatus&id_field_name=BookingID&record_id=${bookingID}`,

  EDIT_USER: (userID) => `${API_BASE_URL}/register/${API_ENV}/edit?user_ID=${userID}`,
  SHOW_USER: (employeeId) => `${API_BASE_URL}/register/${API_ENV}/user?user_ID=${employeeId}`,
  INSERT_USER: `${API_BASE_URL}/register/${API_ENV}/insert`,
  POSITION_LIST: `${API_BASE_URL}/register/api/position_list`,
  STATUS_LIST: `${API_BASE_URL}/register/api/status_list`,

  SERVICES: `${API_BASE_URL}/services/${API_ENV}/view?status=1&branch_user=${getUserID()}`,
  PRICE: `${API_BASE_URL}/price/${API_ENV}/view_app`,
  PRICE_FOR_BOOKING: (bookingId) => `${API_BASE_URL}/price/${API_ENV}/view_app?PriceID=${bookingId}`,

  BRANCHES: `${API_BASE_URL}/branches/${API_ENV}/view?user_ID=${getUserID()}`,
  BRANCHES_LIST: `${API_BASE_URL}/branches/${API_ENV}/view?user_ID=${getUserID()}`,

  EVENTS: `${API_BASE_URL}/booking/${API_ENV}/detail_calendarView?branch_user=${getUserID()}`,
  CREATE_BOOKING: `${API_BASE_URL}/booking/${API_ENV}/create_nulllogin`,
  AVAILABLE_TIMES: `${API_BASE_URL}/booking/${API_ENV}/available`,
  DETAIL_CREATE: `${API_BASE_URL}/booking/${API_ENV}/detail_create`,
  CHECK_AVAILABILITY: `${API_BASE_URL}/booking/${API_ENV}/detail_calendarView?limit=5&branch_user=${getUserID()}`,
  LOGIN: `${API_BASE_URL}/login/${API_ENV}/check`,

  IMAGE_UPLOAD: `${API_AA_GO_URL}/aachat/api/upload`,
  IMAGE_DELETE: `${API_BASE_URL}/img/api/delete`,

  DELETE_PRICE_OPTION: `${API_BASE_URL}/price/${API_ENV}/delete`,
  PRICE_UPDATE: `${API_BASE_URL}/price/${API_ENV}/update`,
  PRICE_CREATE: `${API_BASE_URL}/price/${API_ENV}/create`,
  PRICE_DATA: (serviceID, userID) => `${API_BASE_URL}/price/${API_ENV}/view?priceConn_dataID=${serviceID}&user_ID=${userID}`,

  SEND_EMAIL: `${API_BASE_URL}/send/${API_ENV}/email`,
  SEND_SMS: `${API_BASE_URL}/sms/${API_ENV}/thsms`,
  CREATE_PAYMENT: `${API_BASE_URL}/payments/${API_ENV}/create`,
  PAYMENTS_LIST: `${API_BASE_URL}/payments/${API_ENV}/view?SortBy=TransactionID&SortOrder=DESC&branch_user=${getUserID()}`,

  BOOKING_OVERVIEW: (startDate, endDate, branchID = '', serviceID = '', bookingType = '', employeeID = '', customerID = '', paymentStatus = '', bookingStatus = '') => {
    let apiUrl = `${API_BASE_URL}/report/${API_ENV}/booking_overview?date_from=${startDate}&date_to=${endDate}&limit=399&branch_user=${getUserID()}`;

    if (branchID) apiUrl += `&EmployeeBranch=${branchID}`;
    if (serviceID) apiUrl += `&ServiceIDMain=${serviceID}`;
    if (bookingType) apiUrl += `&BookingType=${bookingType}`;
    if (employeeID) apiUrl += `&EmployeeID=${employeeID}`;
    if (customerID) apiUrl += `&CustomerID=${customerID}`;
    if (paymentStatus) apiUrl += `&PaymentStatus=${paymentStatus}`;
    if (bookingStatus) apiUrl += `&BookingStatus=${bookingStatus}`;

    return apiUrl;
  }

  // Add links here apiUrl
};

export const API_ENDPOINTS = {

  TYPES_CREATE: `${API_BASE_URL}/services/${API_ENV}/types_create`,
  TYPES_EDIT: `${API_BASE_URL}/services/${API_ENV}/types_edit`,
  TYPES_DELETE: `${API_BASE_URL}/services/${API_ENV}/types_del`,

  SERVICES_VIEW_TYPE: `${API_BASE_URL}/services/${API_ENV}/types_view?branch_user=${getUserID()}&user_ID=${getUserID()}`,
  SERVICES_VIEW: `${API_BASE_URL}/services/${API_ENV}/view?branch_user=${getUserID()}`,
  SERVICES_CREATE: `${API_BASE_URL}/services/${API_ENV}/create`,
  SERVICES_EDIT: `${API_BASE_URL}/services/${API_ENV}/edit`,
  SERVICES_DELETE: `${API_BASE_URL}/services/${API_ENV}/delete`,
};

export const API_REVIEWS = {
  VIEW: `${API_BASE_URL}/reviews/${API_ENV}/view?branch_user=${getUserID()}`,
  CREATE: `${API_BASE_URL}/reviews/${API_ENV}/create`,
  EDIT: `${API_BASE_URL}/reviews/${API_ENV}/edit`,
  DELETE: `${API_BASE_URL}/reviews/${API_ENV}/delete`,
};



// Function to handle login
export const loginUser = async (email, password) => {
  const response = await fetch(API_URLS.LOGIN, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  });

  if (!response.ok) {
    throw new Error('Login failed: Incorrect email or password.');
  }

  const data = await response.json();
  return data;
};

// Add this function to fetch CRM system info with error handling
export const fetchCRMSystemInfo = async () => {
  const response = await fetch('https://afaa.website/json/crm-01/crm-systems-info.json');
  if (!response.ok) {
    throw new Error('Failed to fetch CRM system info');
  }
  const data = await response.json();
  
  // Ensure "crm-systems" exists and return the CRM
  if (data && data["crm-systems"]) {
    return data["crm-systems"].find(system => system.id === 1); 
  } else {
    throw new Error('Invalid CRM data structure');
  }
};


